@import "styles/common.module.scss";

body {
  margin: 0px;
  position: relative;
  color: var(--text-color);

  * {
    scrollbar-width: auto;
  }

  &.isWindowsOS {
    * {
      scrollbar-width: thin;
    }
  }
}

a {
  text-decoration: none;
  color: var(--primary-main);
}

@media all and (min-width: 960px) {
  html {
    font-size: 14px;
  }
}

@media all and (max-width: 959px) and (min-width: 600px) {
  html {
    font-size: 12px;
  }
}

@media all and (max-width: 599px) and (min-width: 320px) {
  html {
    font-size: 10px;
  }
}
::-webkit-scrollbar-thumb {
  box-shadow: none;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  background-color: var(--background);

  .content {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    z-index: 1;
  }
}
