@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";

$defaultMarginBottom: 48px;

.paper.contactModal {
  width: 730px;
  height: 795px;
  max-height: 100%;
  max-width: 100%;
  .contentContainer {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 32px 46px 24px;
    overflow-y: auto;
  }
  .asterisk {
    color: var(--warning);
  }
}
.title {
  margin-top: 0;
  margin-bottom: $defaultMarginBottom;
}
.label {
  color: $black-level6;
  font-size: 15px;
  font-weight: $font-weight-400;
  font-family: $secondary-font-family;
  margin-bottom: 12px;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &.mb25 {
    margin-bottom: 25px;
  }

  .col {
    flex: 0 0 48%;
    width: 48%;
    max-width: 48%;
  }
}
.description {
  width: 100%;
  height: 160px;
  border: 1px solid $black-level6;
  border-radius: 2px;
  padding: 12px 24px 12px 12px;
  font-size: 15px;
  font-weight: $font-weight-400;
  line-height: 1.4;
  font-family: $secondary-font-family;
  outline: none;
  resize: none;
  box-sizing: border-box;
  color: $black-level2;

  &::placeholder {
    color: $black-level6;
  }
}
.select {
  margin-bottom: 27px;
}
.footer {
  display: flex;
  .submitBtn {
    margin-right: 16px;
  }
}
