@import "./inputs.module.scss";
@import "texts.module";
@import "./tables.module.scss";
@import "./buttons.module.scss";
@import "./navigations.module.scss";
@import "./mixins.scss";
@import "./src/components/MainSidebar/constants.module";

.wrapperLarge {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.container {
  @media all and (max-width: 599px) and (min-width: 320px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.cardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3px 40px 0 40px;
}

.listContainer {
  @extend .cardContainer;
  height: 100%;
  padding-top: 0;
  overflow: hidden;
  .titleSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 15px;

    .title {
      margin: 0;
      padding: 0;
    }
  }
}

.tableContainer {
  display: flex;
  height: 100%;
}

.title {
  color: var(--text-color);
}

.subTitle {
  font-size: 18px;
  color: var(--text-color);
}

.fontFamilySerif {
  font-family: sans-serif;
}

.fontFamilyRoboto {
  font-family: "Roboto";
}

.fontRegular {
  font-weight: normal;
}

.fontBold {
  font-weight: bold;
}

.textTitle {
  color: var(--action-text-color);
}

.button {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  @extend .flexCenter;
  border: none;
  min-width: 85px;
  cursor: pointer;
  @media all and (max-width: 599px) and (min-width: 300px) {
    font-size: 18px;
  }
}

.submitButton {
  @extend .btn_medium_default;
}
.cancelBtn {
  @extend.btn_medium_secondary;
}

.flex {
  display: flex;
}
.flexCenterColumn {
  display: flex;
  justify-content: center;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexColumn {
  flex-flow: column;
}

.flexRow {
  flex-flow: row;
}

.cursorPointer {
  cursor: pointer;
}

.actionText {
  color: var(--action-text-color);
  cursor: pointer;
}

.defaultTitleText {
  color: $textColorGray;
}

.error {
  color: var(--black-level-5-color);
  padding-top: 6px;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  margin: 0 0 22px 0;
}

.tableBorder {
  border: 1px solid $lightGray;
}

.formControl {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.link {
  @extend .btn_link_medium;
}
.dot {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.statusOn {
  background: var(--success);
  @extend .dot;
}

.statusOf {
  background: orange;
  @extend .dot;
}

.statusFailed {
  background: red;
  @extend .dot;
}

.ellipsesText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copiedIcon {
  path {
    fill: var(--copy-endpoint-color) !important;
  }
}

.copiedText {
  color: var(--copy-endpoint-color);
}

.noFocus {
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
  }
  &:focus-visible {
    outline: none;
    box-shadow: none;
    border-color: transparent;
  }
}

.user-select-none {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@mixin dbScrollableContainer() {
  & {
    padding-right: 40px;
    margin-right: -40px;
    &.hasScrollbar {
      padding-right: 32px;
    }
    &.hasScrollbar.isFirefox {
      padding-right: 29px;
    }
  }
}

.tablePageScrollWrapper {
  @include dbScrollableContainer();
  &.hasScrollbar {
    padding-right: 40px;
  }
  &.hasScrollbar.isFirefox {
    padding-right: 40px;
  }
}
