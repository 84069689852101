// Black (corresponds to dark in figma)
$black: #000000;
$black-level0: #1a0404;
$black-level1: #332627;
$black-level2: #4d3d3e;
$black-level3: #665555;
$black-level4: #806f6f;
$black-level5: #998a8a;
$black-level6: #b2a6a6;
$black-level7: #ccc2c2;
$black-level8: #f5ebeb;
$black-level9: #fffcfd;
$black-level10: #adacad;

$black-level_-2: #0e0202; //dark_-2
$black-level_-1: #120202; //dark_-1
$black-level00: #1d1111; //dark00

$white: #ffffff; // white
$pale: #fff2f3; //pale
$pale-dark: #e5e1e1; //pale dark

$red: #f72a30; //red
$red-dark: #ac2428; //red dark
$red-bright: #ff484e; //red bright

$red-light: #ffeeee;
$green-light: #e9f7ef;

// Gray
$gray: #f1f3f6;
$gray2: #4f4f4f;
$gray3: #b3a6a6; //dark-6
$gray4: #ccc2c2; //dark-7
$grayBtnText: #dddccc;
$grayButton: #c4c4c4;
$lightGray: #e0e0e0;

$green: #6fcf97;
$green-darker: #27ae60;
$green-1: #e1edbf;
$green-2: #27ae60;
$green-4: #525c33;

$blue_light: #e6e7f5;
$blue-1: #ced0fd;
$blue-2: #3a42f8;
$blue-3: #060c9e;
$blue-4: #1c1e5c;
$blue-5: #0a0b40;

$teal-1: #cefafd;
$teal-2: #07b0bb;
$teal-3: #058b94;
$teal-4: #1f4a4d;
$teal-5: #062426;

// figma has orange-3 and orange-4 as same colors for graphs
$orange-1: #fdd9ce;
$orange-3: #5c2b1c;

$pink-1: #fdcee5;
$pink-3: #92054b;
$pink-4: #4d1732;

// warning
$warning: #fea15a;
$warning-bg: #fff8f2;
$warning-dark: #d68c54;
$warning-bg-dark: #1f130b;

// success
$success: #48a651;
$success-bg: #e9f7ef;
$success-dark: #398040;
$success-bg-dark: #0c1c13;

$scrollBarColor: #e5e5e5;

$textColorGray: #9f9f9f;
$textButtonWhite: #ffffff;

$pagePadding: 30px;
$buttonPadding: 10px;

$darkGray: #2d3446;
$darkColor: rgba(0, 0, 0, 0.3);
$darkGrayText: #c3c3c3;

$tableHeadColor: #f1f3f6;

$borderInput: $black-level7;

$alertTitleColor: rgba(0, 0, 0, 0.65);
$alertTextColor: rgba(0, 0, 0, 0.64);

$linearGradient: linear-gradient(
  0deg,
  #312f30 92.86%,
  rgba(49, 47, 48, 0) 106.25%
);

$status-on: $success;

$chart-red: #92054b;
$chart-blue: #060c9e;
$chart-green: #058b94;
$chart-yellow: #9d2a06;

$graph-color-1: $blue-2;
$graph-color-2: $teal-2;

$faded-graph-color-1: rgba(58, 66, 248, 0.3);
$faded-graph-color-2: rgba(7, 176, 187, 0.3);
$faded-graph-color-3: rgba(197, 7, 102, 0.3);
$faviconRunning: $status-on;

//Export variables to usage in js files;
:export {
  black9: $black-level9;
  black8: $black-level8;
  black6: $black-level6;
  black5: $black-level5;
  black4: $black-level4;
  black2: $black-level2;
  black1: $black-level1;
  black0: $black-level0;
  black00: $black-level00;
  white: $white;
  red: $red;
  redDark: $red-dark;
  redBright: $red-bright;

  teal2: $teal-2;
  teal5: $teal-5;
  blue2: $blue-2;
  blue5: $blue-5;

  // legacy, remove later
  primaryMain: $red;
  primaryDark: $black-level2;
  secondaryMain: $black-level2;

  transparent: rgba(0, 0, 0, 0);
  faviconRunning: $status-on;
  faviconError: $warning;
}
