@import "styles/common.module.scss";

.wrapper {
  @extend .wrapperLarge;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .flexColumn;

  .checkContainer {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    @extend .container;
    .cardTitle {
      @extend .title;
      margin-bottom: 2px;
      margin-top: 0;
    }
    .emailText {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      margin-top: 0;
      margin-bottom: 0;
      color: var(--black-level-5-color);
    }
    .cardSubTitle {
      margin-top: 20px;
      height: 28px;
      margin-bottom: 0px;

      &.link {
        @extend .link;
      }
    }
  }
}
