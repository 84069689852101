@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";

.header {
  background-color: var(--black-level-9-color);
  padding: 32px 46px 15px;
  border-bottom: 1px solid var(--black-level-8-color);

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: var(--black-level-0-color);
    font-family: $base-font-family;
  }

  .headerContent {
    padding-top: 24px;
  }
}

.body {
  padding: 34px 46px 0;
}

.footer {
  padding: 24px 46px;
}
