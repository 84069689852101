$base-font-family: "Poppins", sans-serif;
$secondary-font-family: "Inter", sans-serif;
$code-font-family: "Roboto Mono", sans-serif;

$font-weight-600: 600;
$font-weight-500: 500;
$font-weight-400: 400;

$dialog-horizontal-padding: 40px;
$dialog-vertical-padding: 40px;

$labelDefaultFontSize: 13px;

@mixin dialogBottomBlock {
  padding-bottom: $dialog-vertical-padding;
}

:export {
  baseFontFamily: $base-font-family;
  secondaryFontFamily: $secondary-font-family;
  codeFontFamily: $code-font-family;
}
