@import "styles/variables.colors.module";

.root {
  height: 32px;
  line-height: 1.4;
  font-size: 13px;
  color: var(--action-menu-item-text-color);
  padding: 0 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 210px;
  box-sizing: border-box;

  &:focus,
  &:hover {
    background-color: var(--action-menu-item-background-color-hover);
    color: var(--action-menu-item-text-color-hover);
  }

  .icon {
    margin-right: 10px;
    display: flex;
  }

  .text {
    font-size: 13px;
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
