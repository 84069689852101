@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";

.root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .wrapper {
    margin: auto;

    .loaderContainer {
      min-height: 48px;
      margin-bottom: 13px;
      position: relative;
      text-align: center;

      .loadingIcon {
        margin: auto;
        display: flex;
        position: relative;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;

        .loading {
          position: absolute;
          left: 0px;
          top: 0px;
          justify-content: center;
          display: flex;
          align-items: center;
          bottom: 0px;
          right: 0px;
        }

        .innerIcon {
          width: 24px;
          height: 24px;
          background-color: var(--success);
          border-radius: 24px;
          opacity: 0.3;
        }

        .rotation {
          animation-name: ckw;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
          animation-duration: 1s;
        }
      }
    }

    .title {
      font-size: 24px;
      font-family: $base-font-family;
      font-weight: $font-weight-600;
      color: var(--welcome-screen-pending-subscription-title-color);
      line-height: 36px;
      text-align: center;
    }

    .description {
      margin-top: 20px;
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      line-height: 140%;
      color: var(--welcome-screen-pending-subscription-description-color);
    }
  }

  .contactButtonContainer {
    padding-bottom: 64px;
    font-size: 15px;
  }
}
