@import "styles/variables.colors.module";

.title {
  margin: 0 40px 0 0;
  opacity: 1;
  transition: margin-right 150ms linear, opacity 150ms ease-in;

  @media all and (max-width: 770px) {
    width: 0;
    opacity: 0;
    margin-right: 0;
    transition: opacity 100ms linear, width 150ms linear,
      margin-right 150ms linear;
  }
}

.searchInputContainer {
  flex-grow: 1;
  min-width: 152px;
  margin-right: 40px;
  transition: margin-right 150ms linear;

  .searchFieldWrapper {
    max-width: 464px;
  }
}

.noResultsMessageContainer {
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .text {
    font-size: 13px;
    line-height: 1.4;
    margin-top: 16px;
    color: var(--user-list-no-result-text-color);
  }
}

.tableContainer {
  height: 100%;
}

.listContainer {
  position: relative;
  height: calc(100% - 212px);
}

.dbLink {
  color: var(--action-text-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.actionIcon {
  cursor: pointer;
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.row {
  height: 46px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  user-select: text;

  &:hover {
    .actionIcon {
      visibility: visible;
    }
  }
}

.cell {
  padding-left: 14px;
  height: 100%;
  align-items: center;
  font-size: 13px;
  color: var(--user-list-cell-text-color);
  font-weight: 500;

  &.disabled {
    color: var(--user-list-cell-text-color-disabled);
  }
}

.emailCell {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.statusLabel {
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--user-list-status-label-text-color);
  background-color: var(--user-list-status-label-background-color);

  &.pendingApproval {
    background-color: var(--user-list-status-label-background-color-approval);
  }

  &.pendingInvitation {
    background-color: var(--user-list-status-label-background-color-invitation);
  }

  &.externalUser {
    background-color: var(--user-list-status-label-background-color-external);
  }
}

.tooltip {
  width: 200px;
}

.selectCell {
  padding-left: 0px;
  display: flex;
  justify-content: space-around;
}
