@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";

.root {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 30px * 2);
  align-items: center;

  .title {
    max-width: 600px;
    letter-spacing: -0.01em;
    font-size: 24px;
    font-family: $base-font-family;
    font-weight: $font-weight-600;
    color: var(--error-screen-title-text-color);
    line-height: 36px;
    text-align: center;
  }

  .description {
    margin-top: 10px;
    font-family: $secondary-font-family;
    font-size: 15px;
    line-height: 21px;
    color: var(--error-screen-description-text-color);
    font-weight: 500;
    max-width: 450px;
    text-align: center;
  }

  .imageContainer {
    &.TOP {
      margin-bottom: 40px;
    }

    &.BOTTOM {
      margin-top: 80px;
    }
  }

  .footer {
    margin-top: 156px;
    color: var(--error-screen-description-text-color);
    &.smallMargin {
      margin-top: 40px;
    }
  }
}
