.image.forced.forced2 {
  margin-bottom: 10px;
}
.footer.forced {
  margin-top: 30px;
}

.compass {
  position: relative;
  width: 258px;
  height: 258px;
  .base,
  .arrow {
    position: absolute;
    top: 0;
    left: 0;
  }
  .base {
    z-index: 1;
  }
  .arrow {
    z-index: 2;
    transform: rotate(20deg);
    animation: spin 2s alternate infinite;
  }
}

@keyframes spin {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(80deg);
  }
  100% {
    transform: scale(1) rotate(-180deg);
  }
}

@-o-keyframes spin {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
  }
  50% {
    -webkit-transform: scale(1) rotate(80deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(-180deg);
  }
}

@-moz-keyframes spin {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
  }
  50% {
    -webkit-transform: scale(1) rotate(80deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(-180deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
  }
  50% {
    -webkit-transform: scale(1) rotate(80deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(-180deg);
  }
}
