@import "styles/common.module.scss";

.accountRoot {
  @extend .wrapperLarge;
}
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  .accountContainer {
    margin: auto 0;
    min-width: 688px;
    @media all and (max-width: 599px) and (min-width: 320px) {
      min-width: 360px;
    }
    @extend .container;

    .titleSection {
      display: flex;
      .title {
        margin-top: 0;
      }
      @extend .flexColumn;
      .accountSubTitle {
        margin-top: 6px;
        @extend .subTitle;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    margin-top: 140px;
  }
  .footerRight {
    margin-left: 20px;
  }
  .footerText {
    font-weight: 400;
    font-size: 13px;
    color: var(--black-level-2-color);
  }
  .privacy {
    line-height: 1.4;
  }
  .copyright {
    margin: 0 auto;
  }
  .footerLink,
  .contactSupportLink {
    text-decoration: unset;
    cursor: pointer;
    color: var(--primary-main);
    margin-left: 3px;

    &:hover,
    &:active {
      color: var(--primary-main-light);
      text-decoration-line: underline;
    }
  }
  .backToLogin {
    margin-top: 24px;
    margin-left: 18px;
    align-self: flex-start;

    .backToLoginIcon {
      width: 16px;
      height: 16px;
      margin-right: 14px;
      color: $black-level5;
      fill: $black-level5;

      & > path {
        fill: $black-level5;
      }
    }

    .backToLoginLink {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 1.4;
      color: $black-level5;

      &:hover,
      &:focus,
      &:visited,
      &:active {
        font-weight: 400;
        font-size: 15px;
        line-height: 1.4;
        color: $black-level5;
      }
    }
  }
}

.input {
  margin: 0;
}

.linkText {
  font-size: 18px;
  text-decoration: unset;
  color: var(--action-text-color);
  cursor: pointer;
}

.buttons {
  margin-top: 20px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.btn {
  @extend .submitButton;
  min-width: 123px;
  .disabledIcon {
    display: none;
  }
  &:disabled {
    .disabledIcon {
      display: block;
      line-height: unset;
      padding-top: 2px;
      padding-left: 5px;
    }
    .btnIcon {
      display: none;
    }
  }
}
.btnIcon {
  padding-left: 5px;
  padding-top: 2px;
  line-height: unset;
}

.tableContainer {
  margin-left: 24px;
  overflow-x: auto;
}

.table {
  @extend .tableBorder;
}

.tableHead {
  background-color: $tableHeadColor;
}

.tableHeadCell {
  color: var(--action-text-color);
}

.actionIcon {
  cursor: pointer;
}

.addBtn {
  padding: 7px 0 7px 0;
}

.editBtn {
  margin-left: auto;
}

.cancelButton {
  @extend .button;
}

.displayError {
  @extend .error;
  height: 36px;
  display: flex;
  flex-flow: row;
  margin-bottom: 6px;

  &.hidden {
    visibility: hidden;
  }

  &.hint {
    color: var(--black-level-4-color);
  }
}
.errorImg {
  width: 12px;
  height: 12px;
  margin-right: 7px;
  margin-top: 4px;
  flex-shrink: 1;
}

.linkItem {
  color: inherit;
  cursor: pointer;
}

.selectDropdown {
  z-index: 30000;
}
.formControl {
  @extend .formControl;
}
.inlineFormControl {
  margin-bottom: 24px;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  gap: 48px;

  .formControl {
    position: relative;
    width: 305px;

    .passwordHint,
    .accountNameWarning {
      padding: 0px;
      margin-top: -8px;
      color: var(--black-level-5-color);
      font-size: 13px;
      line-height: 1.4;
      margin-bottom: 0;

      .warningIcon {
        width: 24px;
        height: 24px;
        transform: translate(-12.5%, 25%);
        color: $black-level6;
      }
    }

    .phoneFieldsContainer {
      display: flex;
      justify-content: space-between;

      .countryCodeContainer {
        margin-right: 20px;
        width: 122px;
      }
    }
  }
  .fieldSection {
    margin-left: auto;
    width: 300px;
    position: relative;
  }
}
.submitButton {
  width: 180px;
}
.linkWrap {
  margin-left: auto;
  color: var(--black-level-2-color);
  font-size: 15px;
  width: 300px;
}
.actionLink {
  @extend .actionText;
  @extend .fontRegular;
  @extend .link;
  margin-left: 2px;
  font-size: 15px;
}
.errorPassword {
  height: 40px;
  max-width: 420px;
}

.eyeIcon {
  cursor: pointer;
}
