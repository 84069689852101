@import "styles/variables.colors.module";

$switchWidth: 20px;
$sliderWidth: $switchWidth - 4px;

.wrapper {
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 8px;
}

.switch {
  display: block;
  position: relative;
  width: 36px;
  height: 20px;
  background: var(--toggle-background-color);
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s ease;
  box-sizing: border-box;

  .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1px;
    left: 1px;
    width: $sliderWidth;
    height: $sliderWidth;
    background: $white;
    border-radius: 50%;
    transition: 0.4s ease;
    box-sizing: border-box;
  }

  .input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      transform: translate($sliderWidth);
    }
  }

  &.sm {
    width: 28px;
    height: 16px;
    .slider {
      border-radius: 46px;
      width: 14px;
      height: 14px;
    }
    &.checked .slider {
      transform: translate(12px);
    }
  }

  &.disabled {
    cursor: initial;
    opacity: 0.5;
    ~ .label {
      cursor: initial;
    }
  }

  &.vertical {
    width: 20px;
    height: 36px;

    .input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        transform: translate(0, $sliderWidth);
      }
    }
  }
}

.checked {
  background: var(--toggle-background-color-checked);
}

.label {
  font-size: 13px;
  line-height: 1.4;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--toggle-label-color);
  user-select: none;
  margin-left: 8px;
  cursor: pointer;
}

.tooltip {
  max-width: 200px;
}
