@import "styles/variables.fonts.module";
@import "styles/variables.colors.module";

// TODO re-work from using prop variant="outlined" for more control over styles

.root {
  width: 100%;

  .inputContainer {
    fieldset {
      border-radius: 2px;
      border: {
        width: 1px;
        style: solid;
        color: var(--search-input-input-border-color);
      }
    }

    &:hover fieldset {
      border-color: var(--search-input-input-border-color-hover);
    }

    input {
      height: 15px;
      font-size: 13px;
      color: var(--search-input-input-text-color);
      width: 435px;
      max-width: 100%;

      /* Chrome, Firefox, Opera, Safari 10.1+ */
      &::placeholder, 
      /* Internet Explorer 10-11 & Microsoft Edge */
      &:-ms-input-placeholder {
        color: var(--search-input-input-placeholder-color);
      }

      &:disabled {
        color: var(--search-input-input-text-color);
      }
    }
  }

  .iconContainer {
    cursor: pointer;
    margin-right: -8px;
  }

  .inputFocused {
    fieldset {
      border-color: var(--search-input-input-border-color-active) !important;
    }
  }
}
