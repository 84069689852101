@import "styles/variables.colors.module";
@import "styles/tables.module";

$defaultLabelWidth: 54px;
$defaultLabelMarginLeft: 12px;
$defaultLabelMarginRight: 8px;
$rowHeight: 24px;
$rowMarginBottom: 4px;

.engineItem {
  color: var(--engine-item-text-color);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  border: 1px solid transparent;
  transition: background-color 0.2s ease;
  position: relative;
  &.sqlwMode {
    padding-right: 48px !important;
  }

  &.systemEngine {
    border-bottom: 1px solid var(--black-level-8-color);
  }

  &.clickable {
    cursor: pointer;
  }

  &.readOnly {
    padding: 0;
    cursor: default;
  }

  &:hover:not(.readOnly) {
    background-color: var(--engine-item-background-color-hover);
  }

  &.active {
    border-color: var(--engine-item-border-color-active);
    cursor: default;
  }

  &.disabled {
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }

  .nameAndActions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .nameContainer {
    display: flex;
    min-width: 0;
    margin-right: auto;
    margin-bottom: $rowMarginBottom;

    .engineName {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 13px;
      font-weight: 400;
      color: var(--engine-item-name-text-color);
      height: 24px;
      &.default {
        width: calc(100% - #{$defaultLabelWidth} - #{$defaultLabelMarginLeft});
      }
      &.disabled {
        color: var(--engine-item-name-text-color-disabled);

        .namePrefix {
          color: var(--engine-item-name-text-color-disabled);
        }
      }
      .namePrefix {
        color: var(--engine-item-name-prefix-text-color);
      }
      .name,
      .namePrefix {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        box-sizing: border-box;
      }
    }
    .defaultLabel {
      width: $defaultLabelWidth;
      height: 24px;
      box-sizing: border-box;
      margin-left: $defaultLabelMarginLeft;
      margin-right: $defaultLabelMarginRight;
      margin-bottom: $rowMarginBottom;
      padding: 0;
      text-align: center;
      line-height: 23px;
      font-size: 11px;
      flex-shrink: 0;
    }
  }

  .actionsCol {
    height: $rowHeight;
    margin-bottom: $rowMarginBottom;
    display: flex;
    align-items: center;
  }

  .text {
    font-size: 13px;
    font-weight: 400;

    .iconContainer {
      margin-right: 4px;
    }
  }

  .iconContainer {
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .systemEngineIcon {
    margin-right: 16px;
  }

  .systemEngineDescription {
    color: $black-level5;
    font-weight: 400;
    font-size: 13px;
    height: 25px;
  }

  .engineSpecs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: nowrap;
    .specCol {
      margin-bottom: $rowMarginBottom;
      display: flex;
      flex-wrap: wrap;
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
    .specItem {
      display: flex;
      align-items: center;
      color: var(--engine-item-spec-text-color);

      &:not(:first-child) {
        margin-left: 16px;
      }
      &.cpu {
        max-width: 70px;
      }
      &.storage {
        max-width: 100px;
      }
      &.ram {
        max-width: 135px;
      }
    }

    &.disabled {
      .specItem {
        color: var(--engine-item-spec-text-color-disabled);
      }
    }
  }
  .totals {
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-bottom: $rowMarginBottom;
    .totalItem {
      color: var(--engine-item-spec-text-color);

      &:not(:first-child) {
        margin-left: 15px;
      }

      &.price {
        margin-left: 10px;
        max-width: 105px;
      }
    }

    &.disabled {
      .totalItem {
        color: var(--engine-item-spec-text-color-disabled);
      }
    }
  }
  .engineStatusIcon {
    margin-right: 23px;
    display: flex;
    align-items: center;
  }
  .checkIcon {
    cursor: default;
    position: absolute;
    opacity: 1;
    top: 10px;
    right: 12px;
  }
}

.engineStatus {
  display: flex;
  align-items: center;
  .engineStatusText {
    margin-left: 4px;
    max-width: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--success);
    font-weight: 500;
    font-size: 13px;
    box-sizing: border-box;
    white-space: nowrap;
  }
}

.statusTooltip.forced {
  max-width: 320px;
}

.disabledOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
