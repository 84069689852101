@import "styles/variables.colors.module.scss";
.root {
  display: flex;
  font-weight: 500;
  font-size: 13px;
  align-items: center;
  max-width: 100%;
  overflow: hidden;

  .iconContainer {
    margin-right: 4px;
    display: flex;
  }

  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
