@import "../../../styles/common.module";

.general {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    margin: 0;
    margin-top: 32px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
    white-space: unset;
    text-align: center;
    font-family: $base-font-family;
    color: var(--error-screen-title-text-color);
  }

  .support {
    margin-top: 16px;
    font-size: 15px;
    font-weight: 500;
    font-family: $secondary-font-family;
    line-height: 1.4;
    text-align: center;
    color: var(--error-screen-description-text-color);
  }
}

.footer {
  position: absolute;
  font-family: $secondary-font-family;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  bottom: 64px;
  color: var(--error-screen-description-text-color);
}
