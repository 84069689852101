@import "styles/variables.colors.module";

.toggleWrap {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 0 0 12px;
}

// Toggle component overrides
.switch {
  border: 1px solid var(--theme-switcher-switch-border-color);
  background: var(--theme-switcher-switch-background-color) !important;
}

.slider {
  background: var(--theme-switcher-slider-background-color) !important;
}

.wrapper {
  padding: 0;
}
