@import "styles/variables.colors.module.scss";

.sortIconWrap {
  display: flex;
  flex-flow: column;
  margin-left: auto;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sortIcon {
  font-weight: bold;
  cursor: pointer;

  &:first-child {
    margin-bottom: 3px;
  }
}

.sortIconActive {
  &:first-child {
    margin-bottom: 3px;
  }
  path {
    fill: var(--black-level-2-color);
  }
}
