@import "styles/variables.colors.module";

.paper {
  background: var(--action-menu-paper-background-color);
  border: {
    width: 1px;
    style: solid;
    color: var(--action-menu-paper-border-color);
  }

  .list {
    .divider {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}
