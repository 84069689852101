@import "styles/variables.colors.module";
@import "styles/tables.module";

.root {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  flex-shrink: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: center;

  &.visible {
    width: auto;
    height: 100%;
    max-width: 100%;
  }
  .actionBtn {
    cursor: pointer;
    @include actionIconEffect();
    &:not(:first-child) {
      margin-left: 8px;
    }
    opacity: 1;
    &.checkIcon {
      cursor: default;
      opacity: 1;
    }
    &.disabled {
      cursor: default;
      opacity: 0.4;
    }
  }

  .message {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    color: var(--engine-item-prompt-panel-message-text-color);
  }
}
