@import "variables.colors.module";
@import "variables.fonts.module";

.secondary {
  font-family: $secondary-font-family;
  font-size: 13px;
  line-height: 18px;
}

.code-snippet {
  font-family: $code-font-family;
  font-style: normal;
  font-weight: normal;
  color: var(--black-level-5-color);

  background-color: var(--black-level-0-color);
  border-radius: 2px;
}
.code-snippet-large {
  @extend .code-snippet;
  font-size: 15px;
  line-height: 18px;
}
.code-snippet-small {
  @extend .code-snippet;
  font-size: 13px;
  line-height: 15px;
}
