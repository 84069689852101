@import "styles/common.module";

.root {
  z-index: 1501 !important;
}
.statusTooltip.forced {
  z-index: 1502;
}
.description {
  margin-top: 6px;
  min-height: 38px;
  &.marginBottom {
    margin-bottom: 40px;
  }
}
.paperBig {
  height: 334px;
  &.hasEngines {
    height: auto;
    max-height: 678px;
  }
  .enginesList {
    max-height: 308px;
    overflow-y: auto;
    border: 1px solid var(--black-level-8-color);
    .engineItem {
      padding: 10px 16px;
    }
  }
  .text {
    font-size: 15px;
    color: var(--black-level-3-color);
    line-height: 1.4;
    font-weight: 400;
  }
}
.dialogTitle {
  margin-bottom: 19px;
}
.paper.forced.forced2 {
  height: 334px;
}
.engineIntermediaryPaper {
  height: 293px;
}
.paper.forced.forced2,
.paperBig.forced.forced2,
.engineIntermediaryPaper.forced.forced2 {
  max-width: 665px;
  margin: 0;
}
.paperBig.forced.forced2 {
  max-width: 705px;
  margin: 0;
}
