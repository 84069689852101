@import "styles/variables.colors.module.scss";

// General pallete
$background: $white;

$primary-main: $red;
$primary-main-light: $red-bright;

$warning: $warning;
$warning-bg: $warning-bg;

$success: $success;
$success-bg: $success-bg;

$text-color: $black-level0;
$title-color: $black-level0;

.light {
  // General colors
  --background: #{$background};
  --primary-main: #{$primary-main};
  --primary-main-light: #{$primary-main-light};
  --warning: #{$warning};
  --warning-bg: #{$warning-bg};
  --success: #{$success};
  --success-bg: #{$success-bg};
  --text-color: #{$text-color};
  --title-color: #{$title-color};
  --link-color: #{$primary-main};
  --action-text-color: #{$primary-main};

  // Icons colors change follow patterns (dark theme color will be different)
  --svg-default-primary-theming: #{$primary-main};
  --svg-default-primary-alternative-theming: #{$primary-main};
  --svg-default-dark0-theming: #{$black-level0};
  --svg-default-dark2-theming: #{$black-level2};
  --svg-default-dark5-theming: #{$black-level5};
  --svg-default-dark7-theming: #{$black-level7};
  --svg-default-dark8-theming: #{$black-level8};
  --svg-default-white-theming: #{$white};
  --svg-default-white-alternative-theming: #{$white};

  --black-level-0-color: #{$black-level0};
  --black-level-1-color: #{$black-level1};
  --black-level-2-color: #{$black-level2};
  --black-level-3-color: #{$black-level3};
  --black-level-4-color: #{$black-level4};
  --black-level-5-color: #{$black-level5};
  --black-level-6-color: #{$black-level6};
  --black-level-7-color: #{$black-level7};
  --black-level-8-color: #{$black-level8};
  --black-level-9-color: #{$black-level9};
  --black-level-10-color: #{$black-level10};
  --white: #{$white};

  --editor-constant-color: #ad1f66;
  --editor-function-color: #6c70c6;
  --editor-keyword-color: #739726;
  --editor-string-color: #2c9096;
  --editor-string-doublequote-color: #c1752f;
  --editor-string-backtick-color: #a82a41;
  --editor-content-bg-color: #{$black-level9};
  --editor-identifier-color: #{$black-level3};
  --editor-type-color: #{$black-level6};
  --editor-gutter-color: #{$black-level7};

  --copy-endpoint-color: #{$green-darker};
  --resize-handler-button-color: #{$grayButton};

  --chart-red-color: #{$chart-red};
  --chart-blue-color: #{$chart-blue};
  --chart-green-color: #{$chart-green};
  --chart-yellow-color: #{$chart-yellow};

  --graph-color-1: #{$blue-2};
  --graph-color-2: #{$teal-2};

  --faded-graph-color-1: #{$faded-graph-color-1};
  --faded-graph-color-2: #{$faded-graph-color-2};
  --faded-graph-color-3: #{$faded-graph-color-3};

  --btn_link-color: #{$primary-main};
  --highlight: #{$primary-main};

  --sqlw-search-left-button-background: url(/assets/icons/ArrowLeftBlack.svg);
  --scrollbar-background-color: $black-level0;
  // Components

  // Input Field
  $inputFieldElements: (
    "input-text-color": $black-level2,
    "input-text-color-disabled": $black-level6,
    "border-bottom-color": $black-level7,
    "border-bottom-color-focused": $black-level2,
    "border-bottom-color-disabled": $black-level8,
    "prefix-text-color": $black-level5,
    "prefix-text-color-disabled": $black-level6,
    "error-icon-color": $warning,
    "error-text-color": $black-level5,
    "asterisk-color": $warning,
  );

  // SearchInput
  $searchInputElements: (
    "input-text-color": $black-level2,
    "input-text-color-disabled": $black-level6,
    "input-placeholder-color": $black-level6,
    "input-border-color": $black-level7,
    "input-border-color-hover": $black-level5,
    "input-border-color-active": $black-level2,
    "input-border-color-error": $warning,
  );

  // Select
  $selectElements: (
    "root-text-color": $black-level2,
    "root-text-color-disabled": $black-level6,
    "root-bottom-border-color": $black-level7,
    "root-bottom-border-color-opened": $black-level2,
    "root-bottom-border-color-disabled": $black-level8,

    "icon-color": $black-level3,
    "icon-color-disabled": $black-level3,

    "paper-background-color": $white,
    "paper-border-color": $black-level8,
    "paper-no-results-text-color": $black-level3,

    "search-box-border-color": $black-level8,
    "search-box-background-color": $white,
  );

  // EmojiSelect
  $emojiSelectElements: (
    "selected-icon-border-color": $black-level8,
    "list-item-background-color-hover": $black-level8,
    "list-item-border-color-hover": $black-level8,
    "list-item-border-color-selected": $black-level1,
  );

  //AccountSelector
  $accountSelectorElements: (
    "list-border-color": $black-level8,
    "item-background-color-hover": $black-level8,
    "item-background-color-selected": $black-level8,
  );

  // Button
  $buttonElements: (
    "primary-background-color": $primary-main,
    "primary-background-color-hover": $primary-main-light,
    "primary-background-color-disabled": $black-level7,
    "primary-text-color": $white,
    "primary-text-color-disabled": $black-level8,
    "primary-loading-color": $white,

    "secondary-background-color": $white,
    "secondary-border-color": $black-level7,
    "secondary-border-color-hover": $black-level5,
    "secondary-border-color-disabled": $black-level7,
    "secondary-text-color": $black-level2,
    "secondary-text-color-disabled": $black-level7,
    "secondary-icon-color": $black-level5,
    "secondary-icon-color-hover": $black-level5,
    "secondary-icon-color-disabled": $black-level7,
    "secondary-loading-color": $black-level2,
  );

  //EmptyList
  $emptyListElements: (
    "description-text-color": $black-level2,
  );

  //EmptyDatabases
  $emptyDatabasesElements: (
    "description-text-color": $black-level2,
  );

  //ActionMenu
  $actionMenuElements: (
    "paper-background-color": $white,
    "paper-border-color": $black-level8,
  );

  //ActionMenuItem
  $actionMenuItemElements: (
    "text-color": $black-level2,
    "text-color-hover": $black-level2,
    "text-color-disabled": $black-level7,
    "background-color-hover": $black-level8,
    "icon-color": $black-level5,
    "icon-color-hover": $black-level2,
    "icon-color-disabled": $black-level7,
  );

  //SnackBarView
  $snackBarElements: (
    "border-color": $black-level8,
    "border-color-warning": $warning,
    "border-color-success": $success,
    "border-color-loading": $black-level8,

    // they differ on dark theme
    "text-color": $black-level2,
    "text-color-warning": $black-level2,
    "text-color-success": $black-level2,
    "text-color-loading": $black-level2,

    "background-color": $black-level9,
    "background-color-warning": $warning-bg,
    "background-color-success": $success-bg,
    "background-color-loading": $black-level9,

    "icon-color": $black-level5,
    "icon-color-warning": $warning,
    "icon-color-success": $green-2,
    "icon-color-loading": $primary-main,

    "link-color": $primary-main,
    "link-color-hover": $primary-main-light,
  );

  //Spinner
  $spinnerElements: (
    "text-color": $black-level1,
    "icon-color": $black-level5,
    "icon-color-primary": $primary-main,
    "icon-color-success": $success,
    "icon-color-warning": $warning,
    "icon-color-disabled": $black-level6,
  );

  //Checkbox
  $checkboxElements: (
    "border-color": $black-level7,
    "border-color-hover": $black-level5,
    "border-color-disabled": $black-level8,
    "border-color-checked": $black-level6,
    "border-color-checked-hover": $black-level5,
    "border-color-checked-disabled": $black-level8,

    "background-color-checked": $black-level6,
    "background-color-checked-hover": $black-level5,
    "background-color-checked-disabled": $black-level8,

    "icon-color-checked": $white,
    "icon-color-checked-disabled": $black-level6,
  );

  // Toggle
  $toggleElements: (
    "label-color": $black-level2,
    "background-color": $black-level7,
    "background-color-checked": $primary-main,
  );

  // RadioButton
  $radioButtonElements: (
    "outer-color": $black-level7,
    "outer-color-hover": $black-level5,
    "outer-color-selected": $black-level7,

    "inner-color-selected": $primary-main,
  );

  // Table
  $tableElements: (
    "header-border-color": $black-level8,
    "header-background": $white,
    // same as background
    "header-cell-text-color": $black-level2,

    "row-background-hover": $black-level9,
    "row-background-selected": $black-level9,

    "cell-border-color": $black-level8,
    "cell-text-color": $black-level2,
    "cell-text-color-disabled": $black-level6,

    "line-color": $primary-main,
    "draggable-color": $primary-main,
  );

  // ErrorScreen (and its copycats)
  $errorScreenElements: (
    "title-text-color": $black-level0,
    "description-text-color": $black-level2,
    "footer-text-color": $black-level2,
    "divider-border-color": $black-level8,
  );

  //EngineItem
  $engineItemElements: (
    "text-color": $black-level2,
    "background-color-hover": $black-level8,
    "border-color-active": $black-level7,

    "name-text-color": $black-level2,
    "name-text-color-disabled": $black-level6,
    "name-prefix-text-color": $black-level5,
    "name-prefix-text-color-disabled": $black-level6,

    "type-icon-fill": $primary-main,
    "type-icon-stroke": $black-level0,

    "spec-text-color": $black-level2,
    "spec-text-color-disabled": $black-level6,

    "prompt-panel-message-text-color": $black-level2,
  );

  $engineFormElements: (
    "title-text-color": $black-level2,
    "iconed-label-text-color": $black-level5,
    "iconed-label-icon-color": $black-level5,
    "checkbox-label-text-color": $black-level5,
  );

  $engineTotalCostElements: (
    "title-text-color": $black-level2,

    "item-background-color": $black-level9,
    "item-border-color": $black-level8,

    "value-text-color": $primary-main,
    "label-text-color": $black-level6,
  );

  $engineScaleElements: (
    "input-text-color": $black-level2,
    "input-border-color": $black-level7,
    "input-border-color-disabled": $black-level8,
    "input-prefix-text-color": $black-level5,

    "slider-label-text-color": $black-level5,

    "slider-thumb-background-color": $primary-main,
    "slider-thumb-background-color-disabled": $black-level7,
    "slider-thumb-border-color": $white,
    "slider-thumb-bar-background-color": $white,

    "slider-root-color": $black-level7,
    "slider-root-color-disabled": $black-level8,

    "slider-rail-color": $black-level7,
    "slider-rail-color-disabled": $black-level8,

    "slider-mark-color": $white,
    "slider-mark-label-color": $black-level5,
    "slider-mark-label-color-active": $black-level2,
  );

  $noEnginesElements: (
    "text-color": $black-level2,
    "icon-fill-color": $black-level8,
    "icon-stroke-color": $black-level7,
  );

  $settingsPresetItemElements: (
    "border-color": $black-level8,
    "border-color-hover": $black-level8,
    "border-color-selected": $black-level6,
    "border-color-disabled": $black-level8,

    "background-color": $white,
    "background-color-hover": $white,
    "background-color-selected": $black-level9,
    "background-color-disabled": $white,

    "icon-fill-color": $black-level8,
    "icon-fill-color-hover": $primary-main,
    "icon-fill-color-selected": $primary-main,
    "icon-fill-color-disabled": $black-level8,

    "icon-stroke-color": $black-level7,
    "icon-stroke-color-hover": $black-level0,
    "icon-stroke-color-selected": $black-level0,
    "icon-stroke-color-disabled": $black-level7,

    "name-text-color": $black-level5,
    "name-text-color-hover": $black-level2,
    "name-text-color-selected": $black-level2,
    "name-text-color-disabled": $black-level5,

    "description-text-color": $primary-main,
  );

  $selectedInstanceTypeElements: (
    "text-color": $black-level2,
    "text-color-disabled": $black-level6,
    "icon-color": $black-level5,
  );

  $instanceTypePickerElements: (
    "sidebar-background-color": $black-level9,
    "sidebar-border-color": $black-level8,

    "icon-fill-color": $black-level5,
    "icon-fill-color-active": $black-level2,
    "icon-fill-color-hover": $black-level2,
    "icon-background-color-hover": $black-level8,

    "category-title-text-color": $black-level6,
  );

  $selectMenuItemTableRow: (
    "col-text-color": $black-level5,
    "col-text-color-first": $black-level2,
  );

  $tickerElements: (
    "border-color": $black-level7,
    "border-color-disabled": $black-level8,

    "icon-color": $black-level5,
    "icon-color-disabled": $black-level7,
  );

  $uploadFileModalElements: (
    "border-color": $black-level7,
    "text-color": $black-level2,
    "action-text-color": $primary-main,
  );

  $responseColumnElements: (
    "query-row-expand-icon-color": $black-level2,
    "query-row-expand-icon-color-hover": $black-level2,

    "query-error-text-color": $warning,
    "query-error-icon-color": $warning,

    "query-success-text-color": $success,
    "query-success-icon-color": $success,

    "query-cancel-text-color": $black-level5,

    "query-running-text-color": $black-level5,
  );

  $responseMessageElements: (
    "text-color": $black-level2,
    "highlight-text-color": $primary-main,
  );

  $sqlTabsElements: (
    "tab-text-color": $black-level5,
    "tab-text-color-hover": $black-level2,

    "tab-border-color-active": $black-level2,

    "tab-icon-color": $black-level5,

    "tab-rename-input-border-color": $black-level6,
    "tab-rename-input-text-color": $black-level5,

    "tab-indicator-color": $black-level5,
    "tab-indicator-color-cancel": $black-level5,
    "tab-indicator-color-success": $success,
    "tab-indicator-color-success-animated": $success-bg,

    "arrow-icon-color": $black-level5,
    "arrow-icon-color-hover": $black-level2,
    "arrow-icon-color-disabled": $black-level7,
    "arrow-icon-color-disabled-hover": $black-level7,
  );

  $keyboardMenuElements: (
    "dropdown-item-text-color": $black-level2,
    "dropdown-item-text-color-disabled": $black-level6,

    "dropdown-item-shortcut-text-color": $black-level0,
  );

  $datagridElements: (
    "border-color": $black-level8,
    "select-border-color": $black-level7,
    "highlight-background-color": $black-level8,
    "column-text-color": $black-level2,

    "header-text-color": $black-level2,
    "header-border-color": $black-level8,
    "header-border-color-selected": $black-level7,
    "header-background-color": $background,

    "header-draggable-color": $primary-main,
    "header-line-color": $primary-main,

    "header-icon-color-active": $black-level2,
    "header-type-icon-color": $black-level7,

    "header-expanded-icon-color": $black-level8,
    "header-expanded-background-color-highlighted": $black-level8,
    "header-expanded-null-text-color": $black-level5,
    "header-expanded-stats-label-text-color": $black-level2,
    "header-expanded-stats-value-text-color": $pink-3,

    "copy-icon-background": $black-level8,
    "copy-icon-color": $black-level5,
    "copy-icon-color-hover": $black-level2,

    "statistics-text-color": $black-level2,
  );

  $queryResultsElements: (
    "statistics-text-color": $black-level2,
    "statistics-icon-color": $black-level5,
    "statistics-count-detail-text-color": $black-level0,

    "tab-text-color": $black-level5,
    "tab-text-color-active": $black-level2,

    "tab-icon-color": $black-level5,
    "tab-icon-color-active": $black-level2,

    "tab-icon-counter-color": $black-level6,

    "tab-list-text-color": $black-level5,

    "statement-icon-color": $black-level5,
    "statement-icon-color-hover": $black-level2,
    "statement-icon-background-color-hover": $black-level8,

    "empty-state-text-color": $black-level2,
  );

  $welcomeScreenElements: (
    "pending-approval-title-color": $black-level0,
    "pending-approval-description-color": $black-level2,
    "pending-approval-footer-color": $black-level2,

    "aws-text-color": $black-level5,
    "aws-title-color": $black-level0,
    "aws-description-color": $black-level2,
    "aws-image-fill": $primary-main,
    "aws-image-stroke": $black-level0,
    "aws-back-icon-color": $black-level5,
    "aws-back-icon-color-active": $black-level2,
    "aws-form-border-color": $black-level8,
    "aws-form-title-color": $black-level0,

    "pending-subscription-title-color": $black-level0,
    "pending-subscription-description-color": $black-level2,
  );

  $textareaElements: (
    "text-color": $black-level2,
    "text-color-disabled": $black-level6,
    "placeholder-color": $black-level6,
    "border-color": $black-level7,
    "border-color-hover": $black-level5,
    "border-color-focus": $black-level2,
    "border-color-disabled": $black-level8,
  );

  $userInvitationModalElements: (
    "label-text-color": $black-level2,
    "error-text-color": $black-level2,
  );

  $userActionConfirmation: (
    "items-text-color": $black-level5,
    "items-border-color": $black-level8,
    "label-text-color": $black-level3,
  );

  $rolesSelectorElements: (
    "border-color": $black-level8,

    "item-background-color-hover": $black-level8,
    "label-text-color": $black-level2,
  );

  $engineStopTimeoutElements: (
    "input-text-color": $black-level2,
    "input-border-color": $black-level7,
    "input-label-color": $black-level5,
    "error-text-color": $black-level5,
    "divider-background-color": $black-level8,
  );

  $queryExplainElements: (
    "header-background-color": $white,
    "header-border-color": $black-level8,
    "header-title-text-color": $black-level2,

    "modes-header-background-color": $pink-1,
    "modes-header-text-color": $pink-3,
    "modes-header-icon-color": $pink-3,
    "modes-header-node-text-color": $black-level3,

    "modes-table-background-color": $orange-1,
    "modes-table-text-color": $orange-3,
    "modes-table-icon-color": $orange-3,

    "modes-description-text-color": $black-level3,
    "modes-description-border-color": $black-level7,
    "modes-dropdown-icon-color": $black-level3,

    "type-operation-background-color": $pink-1,
    "type-operation-text-color": $pink-3,

    "type-attribute-background-color": $blue-1,
    "type-attribute-text-color": $blue-3,

    "type-name-background-color": $teal-1,
    "type-name-text-color": $teal-3,

    "type-index-background-color": $green-1,
    "type-index-text-color": $green-4,

    "operations-storedTable-background-color": $orange-1,
    "operations-storedTable-text-color": $orange-3,
    "operations-coma-text-color": $black-level4,

    "switch-divider-background-color": $black-level8,
    "switch-icon-color": $black-level5,
    "switch-icon-color-active": $black-level2,

    "text-border-color": $black-level8,

    "row-border-color": $black-level8,
    "row-header-background-color": $black-level9,
    "row-node-text-color": $black-level2,
    "row-operation-text-color": $black-level4,

    "sidebar-border-color": $black-level8,
    "sidebar-background-color": $white,
    "sidebar-line-border-color": $black-level8,
    "sidebar-toggle-border-color": $black-level8,
    "sidebar-toggle-background-color-collapsed": $white,
    "sidebar-handle-border-color": $black-level8,
    "sidebar-mode-text-color": $black-level6,
    "sidebar-mode-text-color-hover": $black-level3,
    "sidebar-mode-text-color-active": $black-level3,
    "sidebar-mode-text-color-disasbled": $black-level3,
    "sidebar-viewport-background-color": $pale-dark,
  );

  $themeSwitcherElements: (
    "switch-border-color": $black-level3,
    "switch-background-color": $black-level3,
    "slider-background-color": $black-level0,
  );

  $addEngineButtonElements: (
    "icon-color": $red,
    "icon-color-disabled": $black-level7,
  );

  $labelElements: (
    "text-color": $black-level2,
    "text-color-bordered": $black-level5,
    "background-color": $warning-bg,
    "border-color-bordered": $black-level7,
  );

  $queryFooterElements: (
    "selected-engine-border-color": $black-level7,
    "selected-engine-name-text-color": $black-level4,

    "engine-select-paper-background-color": $white,
    "engine-select-paper-border-color": transparent,
    "engine-select-paper-border-width": 0,
  );

  $aceElements: (
    "search-background-color": $white,
    "search-border-color": $black-level8,

    "search-field-border-color": $black-level8,
    "search-field-text-color": $black-level2,
    "search-field-placeholder-color": $black-level2,

    "search-button-text-color": $black-level2,

    "search-options-button-border-color": $black-level8,
    "search-options-button-text-color": $black-level2,
    "search-options-button-text-color-checked": $white,

    "autocomplete-border-color": $black-level8,
    "autocomplete-active-line-background-color": $black-level8,
    "autocomplete-active-line-background-color-hover": $black-level8,

    "editor-active-line-background-color": $black-level8,

    "gutter-cell-text-color": $black-level7,
  );

  $resizeElements: (
    // will be obsolete once resize handler will be reworked
    "handler-svg": url(/assets/icons/DragBar.svg),
    "query-box-border-color": $black-level8,
    "handle-border-color": $black-level6,
    "handle-line-color": $black-level6,
    "handle-background": $white
  );

  $dialogElements: (
    "background-color": $white,
    "border-color": transparent,
    "title-text-color": $title-color,
    "content-text-color": $black-level3,
  );

  $datagridFiltersElements: (
    "wrapper-background-color": $white,
    "wrapper-border-color": transparent,
    "wrapper-border-width": 0,

    "item-text-color": $black-level2,
    "item-background-color-hover": $black-level8,

    "sort-button-background-color": $white,
    "sort-button-background-color-active": $white,
    "sort-button-text-color": $black-level5,
    "sort-button-text-color-hover": $black-level2,
    "sort-button-text-color-active": $black-level2,
    "sort-button-border-color": $black-level8,
    "sort-button-border-color-active": $black-level6,
    "sort-button-icon-color": $black-level7,
    "sort-button-icon-color-hover": $black-level2,
    "sort-button-icon-color-active": $black-level2,

    "divider-background-color": $black-level8,

    "empty-message-text-color": $black-level2,

    "tabs-tab-text-color": $black-level5,
    "tabs-tab-text-color-active": $black-level2,
    "tabs-tab-border-color-active": $black-level2,

    "tabs-preview-text-color": $black-level2,
    "tabs-preview-header-text-color": $black-level3,
    "tabs-item-text-color": $black-level2,
  );

  $rolesModalElements: (
    "background": $white,
    "description-color": $black-level3,
    "empty-state-color": $black-level2,
    "cell-text-color": $black-level2,
    "cell-text-color-selected": $black-level2,
    "cell-text-color-disabled": $black-level6,
    "cell-header-background": $white,
    "cell-header-color": $black-level2,
    "left-side-border-color": $black-level8,
    "tabs-header-color": $black-level2,
    "tab-selected-border-color": $black-level7,
    "tab-title-color": $black-level2,
    "tab-description-color": $black-level5,
  );

  $componentsList: (
    "input-field": $inputFieldElements,
    "search-input": $searchInputElements,
    "select": $selectElements,
    "emoji-select": $emojiSelectElements,
    "account-selector": $accountSelectorElements,
    "button": $buttonElements,
    "empty-list": $emptyListElements,
    "empty-databases": $emptyDatabasesElements,
    "action-menu": $actionMenuElements,
    "action-menu-item": $actionMenuItemElements,
    "snackbar": $snackBarElements,
    "spinner": $spinnerElements,
    "checkbox": $checkboxElements,
    "toggle": $toggleElements,
    "radio": $radioButtonElements,
    "table": $tableElements,
    "error-screen": $errorScreenElements,
    "engine-item": $engineItemElements,
    "engine-form": $engineFormElements,
    "engine-total-cost": $engineTotalCostElements,
    "engine-scale": $engineScaleElements,
    "no-engines": $noEnginesElements,
    "settings-preset-item": $settingsPresetItemElements,
    "selected-instance-type": $selectedInstanceTypeElements,
    "instance-type-picker": $instanceTypePickerElements,
    "select-menu-item-table-row": $selectMenuItemTableRow,
    "ticker": $tickerElements,
    "upload-file-modal": $uploadFileModalElements,
    "response-column": $responseColumnElements,
    "response-message": $responseMessageElements,
    "sql-tabs": $sqlTabsElements,
    "keyboard-menu": $keyboardMenuElements,
    "datagrid": $datagridElements,
    "query-results": $queryResultsElements,
    "welcome-screen": $welcomeScreenElements,
    "textarea": $textareaElements,
    "user-invitation-modal": $userInvitationModalElements,
    "user-action-confirmation": $userActionConfirmation,
    "roles-selector": $rolesSelectorElements,
    "engine-stop-timeout": $engineStopTimeoutElements,
    "query-explain": $queryExplainElements,
    "theme-switcher": $themeSwitcherElements,
    "add-engine-button": $addEngineButtonElements,
    "label": $labelElements,
    "query-footer": $queryFooterElements,
    "ace": $aceElements,
    "resize": $resizeElements,
    "dialog": $dialogElements,
    "datagrid-filters": $datagridFiltersElements,
    "roles-modal": $rolesModalElements,
  );

  // Pages
  // Databases
  $databasesPageElements: (
    "switch-view-icon-color": $black-level2,
    "switch-view-background-color-hover": $black-level8,
  );

  //DatabaseForm
  $databaseFormElements: (
    "background-color": $white,
    "border-color": $white,
    "divider-background-color": $black-level8,
    "engineListTitleContainer-label-text-color": $black-level2,
    "noEngineContainer-text-color": $black-level2,
    "noEngineContainer-icon-fill-color": $black-level8,
    "noEngineContainer-icon-stroke-color": $black-level7,
    "modal-title-text-color": $title-color,
    "left-side-border-color": $black-level8,
  );

  $databaseCardElements: (
    "border-color": $black-level8,
    "background-color": $white,

    "title-text-color": $black-level2,
    "description-text-color": $black-level5,

    "info-label-text-color": $black-level5,
    "info-value-text-color": $black-level2,

    "action-item-text-color": $black-level5,
    "action-item-background-color-hover": $black-level8,
    "action-item-icon-color": $black-level5,
  );

  $databaseRowElements: (
    "engine-popover-background-color": $white,
    "engine-popover-border-color": transparent,
  );

  $enginesListElements: (
    "title-text-color": $black-level5,

    "icon-color": $black-level10,
    "icon-background-color-hover": $black-level8,
  );

  $userListElements: (
    "cell-text-color": $black-level2,
    "cell-text-color-disabled": $black-level6,
    "cell-text-color-selected": $black-level2,

    "status-label-text-color": $black-level2,
    "status-label-background-color": $black-level6,
    "status-label-background-color-approval": $success-bg,
    "status-label-background-color-invitation": $warning-bg,
    "status-label-background-color-external": $blue-light,

    "no-result-text-color": $black-level2,
  );

  $roleListElements: (
    "no-result-text-color": $black-level2,
  );

  $leftSidebarElements: (
    "drawer-border-color": $black-level8,
    "drawer-background-color": $black-level9,
    "drawer-title-text-color": $black-level2,

    "database-select-border-color": $black-level7,
    "database-select-text-color": $black-level4,

    "bulk-actions-border-color": $black-level8,

    "bulk-actions-icon-color-active": $black-level2,
    "bulk-actions-icon-background-color-hover": $black-level8,
  );

  $rightSidebarElements: (
    "drawer-border-color": $black-level8,
    "drawer-background-color": $black-level9,

    "metadata-section-border-color": $black-level8,
    "metadata-section-empty-views-text-color": $black-level5,

    "metadata-section-title-text-color": $black-level2,
    "metadata-section-title-icon-color": $black-level8,

    "metadata-column-name-text-color": $black-level4,
    "metadata-column-name-text-color-selected": $black-level2,
    "metadata-column-name-text-color-error": $warning,

    "metadata-item-text-color": $black-level4,
    "metadata-item-text-color-hover": $black-level2,
    "metadata-item-background-color-hover": $black-level8,

    "metadata-item-index-text-color-hover": $black-level4,
    "metadata-item-column-type-text-color": $black-level5,

    "metadata-item-selected-text-color": $black-level2,

    "metadata-item-action-icon-color": $black-level5,
    "metadata-item-action-item-color": $black-level2,
    "metadata-item-action-shortcut-color": $black-level0,

    "metadata-list-item-text-color": $black-level4,
    "metadata-list-item-text-color-hover": $black-level2,
    "metadata-list-item-background-color-hover": $black-level8,

    "search-empty-text-color": $black-level2,

    "stats-bar-name-text-color": $black-level2,
    "stats-bar-name-border-color": $black-level8,
    "stats-bar-label-text-color": $black-level2,
    "stats-bar-tooltip-color": $black-level4,
    "stats-bar-value-text-color": $black-level2,
    "stats-bar-chart-border-color": $black-level8,
    "stats-bar-list-border-color": $black-level8,
    "stats-bar-stats-border-color": $black-level8,
    "stats-bar-control-background-color": $black-level8,
    "stats-bar-control-icon-color-disabled": $black-level7,

    "stats-row-label-text-color": $black-level2,
    "stats-row-percentage-text-color": $black-level5,
    "stats-row-value-text-color": $pink-3,
    "stats-row-value-text-color-alternative": $pink-3,
    "stats-row-isGray-text-color": $black-level5,
  );

  $userScriptsElements: (
    "root-border-color": $black-level8,

    "title-text-color": $black-level2,

    "item-name-text-color": $black-level2,
    "item-name-text-color-hover": $black-level0,

    "item-name-highlight-text-color": $primary-main,

    "item-date-text-color": $black-level5,
    "item-date-text-color-hover": $black-level5,

    "item-more-icon-color": $black-level5,
    "item-star-icon-color": $black-level5,
    "item-star-icon-color-active": $black-level5,
    "item-star-icon-background-color-hover": $black-level8,

    "no-results-text-color": $black-level2,
    "no-scripts-text-color": $black-level2,
  );

  $accountInfoElements: (
    "title-text-color": $title-color,

    "costs-border-color": $black-level8,
    "costs-background-color": $black-level9,
    "costs-title-text-color": $title-color,

    "costs-storage-label-text-color": $black-level5,
    "costs-storage-price-text-color": $black-level0,
    "costs-storage-value-text-color": $black-level6,

    "costs-totalRow-label-text-color": $black-level5,
    "costs-totalRow-price-text-color": $black-level0,
    "costs-totalRow-last-border-color": $black-level7,

    "costs-total-label-text-color": $black-level5,
    "costs-total-value-text-color": $primary-main,

    "costs-byEngineHeader-description-text-color": $black-level5,
    "costs-byEngineStatus-text-color": $black-level5,

    "costs-no-engine-text-color": $black-level2,

    "charts-container-border-color": $black-level8,
    "charts-container-background-color": $black-level9,

    "charts-header-text-color": $black-level5,
    "charts-loader-text-color": $black-level5,
    "charts-empty-text-color": $black-level2,

    "charts-tooltip-border-color": $black-level1,
    "charts-tooltipRow-text-color": $black-level5,
    "charts-tooltipRowDivider-border-color": $black-level2,

    "charts-costsStatus-text-color": $black-level5,

    "charts-legendContainer-expand-text-color": $black-level2,
    "charts-legendContainer-item-text-color": $black-level2,
    "charts-legendContainer-item-text-color-disabled": $black-level7,

    "charts-costColorBall-border-color": $white,
    "charts-costColorBall-background-color-disabled": $white,
    "charts-costColorBall-opacity": 1,

    "charts-gridRow-line-color": $black-level8,

    "charts-bottomAxis-line-color": $black-level8,
    "charts-bottomAxis-tickLabel-color": $black-level6,
    "charts-bottomAxis-tickLabel-color-disabled": $black-level7,

    "charts-leftAxis-line-color": rgba(0, 0, 0, 0),
    "charts-leftAxis-tickLabel-color": $black-level6,

    "charts-bars-line-color": $black-level7,

    "charts-barOverlayPattern-color": $black-level7,
    "charts-barOverlayPattern-opacity": 0.7,

    "status-label-color": $black-level5,
    "status-account-color": $black-level2,
  );

  $networkModalElements: (
    "items-container-text-color": $black-level5,
    "items-container-border-color": $black-level8,
    "label-text-color": $black-level5,
    "header-text-color": $black-level5,
    "header-single-text-color": $black-level3,
  );

  $pagesList: (
    "databases": $databasesPageElements,
    "database-form": $databaseFormElements,
    "database-card": $databaseCardElements,
    "database-row": $databaseRowElements,
    "engines-list": $enginesListElements,
    "user-list": $userListElements,
    "role-list": $roleListElements,
    "left-sidebar": $leftSidebarElements,
    "right-sidebar": $rightSidebarElements,
    "user-scripts": $userScriptsElements,
    "account-info": $accountInfoElements,
    "network-modal": $networkModalElements,
  );

  $elementsList: map-merge($componentsList, $pagesList);

  @each $prefix, $list in $elementsList {
    @each $element, $value in $list {
      --#{$prefix}-#{$element}: #{$value};
    }
  }

  // workaround since scrollbars weren't registering css variables
  ::-webkit-scrollbar-thumb {
    background-color: rgba($black-level00, 0.1);
  }

  * {
    scrollbar-color: rgba($black-level00, 0.1);
  }
}
