@import "styles/common.module.scss";

.overlayWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -10;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color ease-in-out 350ms, z-index ease-in-out 350ms;

  &.dimmed {
    background-color: rgba(var(--background), 0.75);
  }
  &.isLoading {
    z-index: 1501;
  }
}
