@import "styles/common.module.scss";
@import "styles/variables.fonts.module";

.paper {
  &:first-child {
    > div {
      &:first-child {
        padding-top: 0;
      }
    }
  }

  &.paperWidthSm {
    max-width: 593px;
    margin: 0;
  }

  &.paperWidthMd {
    max-width: 720px;
  }

  .dialogContent {
    font-size: 15px;
    padding: 0;
    background: var(--dialog-background-color);
    border: 1px solid var(--dialog-border-color);
  }
}

.dialogActions {
  @include dialogBottomBlock;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 0;
  padding-top: 24px;

  .buttonContainer {
    margin-right: 16px;
  }
}
.actionText {
  @extend .link;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.dialogTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 24px;
  font-size: 16px;

  .titleText {
    margin: 0;
    color: var(--dialog-title-text-color);
    overflow: hidden;
  }

  .closeButton {
    border: 0;
    background: 0;
    margin: {
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
    }
  }

  .closeIcon {
    cursor: pointer;
  }
}

.contentWrapper {
  color: var(--dialog-content-text-color);
  padding: $dialog-vertical-padding $dialog-horizontal-padding 0
    $dialog-horizontal-padding;
}
