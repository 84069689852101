@import "styles/variables.colors.module";

.listItem {
  height: 32px;
  line-height: 18px;
  font-size: 13px;
  color: var(--action-menu-item-text-color);
  padding: 0 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 210px;
  box-sizing: border-box;
  outline: none;

  &.sizeMid {
    height: 37px;
    font-size: 15px;
  }

  svg:not([class="statusIcon"]) {
    path,
    rect {
      fill: var(--action-menu-item-icon-color);
    }

    circle {
      stroke: var(--action-menu-item-icon-color);
    }
  }

  svg {
    path[data-role="stroke"],
    rect[data-role="stroke"] {
      fill: none !important;
    }
  }

  &:focus-visible,
  &:hover,
  &.selected {
    background-color: var(--action-menu-item-background-color-hover);
    color: var(--action-menu-item-text-color-hover);

    svg:not([class="statusIcon"]) {
      path,
      rect {
        fill: var(--action-menu-item-icon-color-hover);
      }

      circle {
        stroke: var(--action-menu-item-icon-color-hover);
      }
    }

    path[data-role="stroke"],
    rect[data-role="stroke"] {
      stroke: var(--action-menu-item-icon-color-hover);
      fill: none;
    }

    &.disabled {
      background: none;
      cursor: default;
      color: var(--action-menu-item-text-color-disabled);
      svg:not([class="statusIcon"]) {
        path,
        rect {
          fill: var(--action-menu-item-icon-color-disabled);
        }

        circle {
          stroke: var(--action-menu-item-icon-color-disabled);
        }
      }
    }

    .showOnlyOnHover {
      display: flex;
    }
  }
  &.disabled {
    color: var(--action-menu-item-text-color-disabled);
    svg:not([class="statusIcon"]) {
      path,
      rect {
        fill: var(--action-menu-item-icon-color-disabled);
      }
      circle {
        stroke: var(--action-menu-item-icon-color-disabled);
      }

      path[data-role="stroke"],
      rect[data-role="stroke"] {
        stroke: var(--action-menu-item-icon-color-disabled);
        fill: none;
      }
    }
  }
  &.buttons {
    &:focus,
    &:hover,
    &.selected {
      background-color: transparent;
      color: var(--action-menu-item-text-color-hover);

      svg:not([class="statusIcon"]) {
        path,
        rect {
          fill: var(--action-menu-item-icon-color-hover);
        }
      }
    }
  }
  .iconContainer {
    min-width: 34px;
    display: flex;
    align-items: center;
  }
  .showOnlyOnHover {
    display: none;
  }

  .selectIconContainer {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .text {
    font-size: 13px;
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
