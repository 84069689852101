@import "../../../styles/common.module";

.general {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin: 0;
    font-size: 24px;
    line-height: 1.5;
    white-space: unset;
    text-align: center;
    color: var(--error-screen-title-text-color);
  }

  .support {
    margin-top: 22px;
    font-size: 15px;
    font-family: $secondary-font-family;
    line-height: 1.4;
    text-align: center;
    color: var(--error-screen-description-text-color);
  }
}

.admin {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin: 0;
    margin-top: 13px;
    font-size: 24px;
    line-height: 1.5;
    white-space: unset;
    text-align: center;
    color: var(--error-screen-title-text-color);
  }
  .message {
    margin-top: 22px;
    max-width: 550px;
    font-size: 15px;
    font-family: $secondary-font-family;
    line-height: 1.4;
    text-align: center;
    color: var(--error-screen-description-text-color);
  }
}

.invite {
  margin-top: 68px;
  border-top: 1px solid var(--error-screen-divider-border-color);
  padding: 50px 30px 0 30px;

  .title {
    margin: 0;
    margin-top: 13px;
    font-size: 18px;
    line-height: 1.5;
    white-space: unset;
    text-align: center;
    color: var(--error-screen-description-text-color);
  }
  .form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 19px;
    padding: 0 47px;
    .input {
      flex: 1;
      margin-right: 30px;
    }
    .button {
      flex-shrink: 0;
    }
  }
}
