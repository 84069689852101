@import "styles/variables.colors.module";

.spinner {
  color: var(--spinner-text-color);
  display: flex;
  align-items: center;
  flex-flow: row;

  .text {
    font-size: 13px;
    font-style: normal;
    color: var(--spinner-text-color);
    padding-left: 13px;
  }

  .colorPrimary {
    color: var(--spinner-icon-color-primary);
  }

  .colorSuccess {
    color: var(--spinner-icon-color-success);
  }

  .colorWarning {
    color: var(--spinner-icon-color-warning);
  }

  .colorDisabled {
    color: var(--spinner-icon-color-disabled);
  }

  .colorGeneral {
    color: var(--spinner-icon-color);
  }
}
