body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  overflow: hidden;
}

input {
  margin: 0;
  padding: 0;
  border: none;
}

#root {
  height: 100%;
}
