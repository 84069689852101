@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    font-size: 32px;
    margin-bottom: 56px;
  }

  .list {
    width: 348px;
    max-height: 276px;
    overflow: auto;
    border: 1px solid var(--account-selector-list-border-color);

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      line-height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      text-align: left;
      cursor: pointer;

      &:hover {
        background: var(--account-selector-item-background-color-hover);
      }

      .name {
        font-size: 15px;
      }

      .icon {
        display: flex;
      }
    }

    .selected {
      background: var(--account-selector-item-background-color-hover);
    }
  }
}
