@import "styles/variables.colors.module";

.itemWrap {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 3px 0 0;

  &.noMargin {
    margin: 0;
  }

  .itemLabel {
    font-weight: 500;
    font-size: 13px;
    margin-right: 3px;
    color: var(--black-level-4-color);
    line-height: 1.4;
  }
  .itemValue {
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    line-height: 1.4;
  }
}
