@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";
@import "styles/common.module";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  outline: none;
  border-style: unset;
  white-space: nowrap;
  @extend .user-select-none;

  &.fullWidth {
    width: 100%;
  }

  .text {
    line-height: 14px;
  }

  .leftIconContainer,
  .rightIconContainer {
    display: flex;
  }

  &.large {
    height: 32px;
    font-size: 14px;
    line-height: 32px;
    padding-left: 22px;
    padding-right: 22px;

    .leftIconContainer {
      padding-right: 4px;
    }
    .rightIconContainer {
      padding-left: 4px;
    }
  }

  &.medium {
    height: 26px;
    font-size: 13px;
    line-height: 1.4;
    padding: 4px 16px;

    .leftIconContainer {
      padding-right: 4px;
    }
    .rightIconContainer {
      padding-left: 4px;
    }
  }

  &.small {
    height: 22px;
    width: auto;
    min-width: 66px;

    font-size: 11px;
    line-height: 22px;
    padding-left: 8px;
    padding-right: 12px;

    .leftIconContainer {
      padding-right: 4px;
    }
    .rightIconContainer {
      padding-left: 4px;
    }
  }

  &.tiny {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.primary {
    color: var(--button-primary-text-color);
    border-radius: 2px;
    min-width: 93px;
    background: var(--button-primary-background-color);

    .leftIconContainer,
    .rightIconContainer {
      svg {
        * {
          fill: var(--button-primary-text-color);
        }
      }
    }
    &.small {
      min-width: 55px;
    }
    &:focus,
    &:hover,
    &:active {
      background: var(--button-primary-background-color-hover);
      color: var(--button-primary-text-color);
    }

    &.disabled {
      background: var(--button-primary-background-color-disabled);
      box-shadow: none;
      color: var(--button-primary-text-color-disabled);
      cursor: default;

      .leftIconContainer,
      .rightIconContainer {
        svg {
          * {
            fill: var(--button-primary-text-color-disabled);
          }
        }
      }
    }
  }

  &.secondary {
    min-width: 93px;
    background: var(--button-secondary-background-color);
    border: 1px solid var(--button-secondary-border-color);
    box-sizing: border-box;
    border-radius: 2px;
    color: var(--button-secondary-text-color);
    font-weight: 600;

    .leftIconContainer,
    .rightIconContainer {
      svg {
        * {
          fill: var(--button-secondary-icon-color);
        }
      }
    }

    &:focus,
    &:hover {
      border: 1px solid var(--button-secondary-border-color-hover);

      .leftIconContainer,
      .rightIconContainer {
        svg {
          * {
            fill: var(--button-secondary-icon-color-hover);
          }
        }
      }
    }

    &.disabled {
      border: 1px solid var(--button-secondary-border-color-disabled);
      color: var(--button-secondary-text-color-disabled);
      cursor: default;

      .leftIconContainer,
      .rightIconContainer {
        svg {
          * {
            fill: var(--button-secondary-icon-color-disabled);
          }
        }
      }
    }
    &.small {
      min-width: 55px;
    }
  }

  &.loading {
    .leftIconContainer,
    .rightIconContainer,
    .text {
      visibility: hidden;
    }

    &:hover {
      cursor: default;

      &.primary {
        background: var(--button-primary-background-color-hover);
      }

      &.secondary {
        border: 1px solid var(--button-secondary-border-color-hover);
      }
    }
  }

  .loadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.primary {
    .loadingContainer {
      svg {
        * {
          stroke: var(--button-primary-loading-color);
        }
      }
    }
  }

  &.secondary {
    .loadingContainer {
      svg {
        * {
          stroke: var(--button-secondary-loading-color);
        }
      }
    }
  }
}
