.root {
  margin-bottom: 45px;
  z-index: 2;
  display: flex;
  align-items: flex-end;
}

.buttonClearContainer {
  width: auto;
  margin-left: 32px;
}

.control {
  width: 100%;
}

.menuItemDivider {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.selectHeaderItem {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.roles {
  padding: 8px;
}

.container {
  height: 150px !important;
}
