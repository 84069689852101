@import "styles/variables.colors.module.scss";
.msgBox {
  padding: 12px 20px 12px 14px;
  background-color: var(--snackbar-background-color);
  border: {
    width: 1px;
    style: solid;
    color: var(--snackbar-border-color);
    radius: 2px;
  }

  .textMessage {
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
  }

  .messageTextWrap {
    display: flex;
    align-items: flex-start;
    flex-flow: row;
    font-size: 13px;
    white-space: pre-wrap;

    .icon {
      margin-right: 14px;
      flex-shrink: 0;
    }

    .textMessage {
      font-size: 13px;
      color: var(--snackbar-text-color);
    }
  }

  &.errBox {
    border-color: var(--snackbar-border-color-warning);
    background-color: var(--snackbar-background-color-warning);
    overflow: auto;

    .textMessage {
      color: var(--snackbar-text-color-warning);
    }
  }

  &.successBox {
    border-color: var(--snackbar-border-color-success);
    background-color: var(--snackbar-background-color-success);

    .textMessage {
      color: var(--snackbar-text-color-success);
    }
  }
}
