@import "styles/common.module.scss";

$mainSidebarWidth: 48px;

.root {
  left: $mainSidebarWidth !important;

  &.anchorOriginTopCenter {
    left: 48px !important;
    top: 0px !important;

    padding-top: 20px;
    align-items: flex-start;
    right: 0px;
    width: auto;

    &.dimmed {
      background-color: rgba(0, 0, 0, 0.1);
      left: 0px !important;
      padding-left: 48px;
      bottom: 0px;
    }
  }
}
// creating object with styles
.snackBarContent {
  // connecting it to mui class to override on default
  &[class*="MuiPaper-root"] {
    border: {
      width: 1px;
      style: solid;
      color: var(--snackbar-border-color);
      radius: 2px;
    }
    color: var(--snackbar-text-color);
    background-color: var(--snackbar-background-color);
    box-shadow: none;
    padding: 0 !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    min-height: 44px;
    height: auto;
    margin-left: -$mainSidebarWidth;

    svg {
      path {
        fill: var(--snackbar-icon-color);
      }
    }

    &.errorSnackBar {
      border-color: var(--snackbar-border-color-warning);
      color: var(--snackbar-text-color-warning);
      background-color: var(--snackbar-background-color-warning);

      svg {
        path {
          fill: var(--snackbar-icon-color-warning);
        }
      }
    }
    &.successSnackBar {
      border-color: var(--snackbar-border-color-success);
      color: var(--snackbar-text-color-success);
      background-color: var(--snackbar-background-color-success);

      svg {
        path {
          fill: var(--snackbar-icon-color-success);
        }
      }
    }
    &.loadingSnackBar {
      border-color: var(--snackbar-border-color-loading);
      color: var(--snackbar-text-color-loading);
      background-color: var(--snackbar-background-color-loading);

      svg {
        circle {
          color: var(--snackbar-icon-color-loading);
        }
      }
    }
  }
}
.messageIcon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.messageWrap {
  display: flex;
  align-items: center;
  flex-flow: row;
}
.messageText {
  margin-left: 12px;
  margin-right: 12px;
  font-size: 13px;

  & a {
    text-decoration: unset;
    cursor: pointer;
    color: var(--snackbar-link-color);

    &:hover,
    &:active {
      color: var(--snackbar-link-color-hover);
      text-decoration-line: underline;
    }
  }
}

.closeImg {
  cursor: pointer;
}
