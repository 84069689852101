.root {
  display: flex;
  width: 100%;
  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: row;
  }

  &.justifyContent {
    justify-content: space-between;

    .child {
    }
  }

  &.alignCenter {
    align-items: center;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.noWrap {
    flex-wrap: nowrap;
  }
  &.wrapReverse {
    flex-wrap: wrap-reverse;
  }
}
