@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";

.backIcon {
  margin-top: 20px;
  margin-left: 20px;
  display: inline-flex;
  align-items: center;
  color: var(--welcome-screen-aws-back-icon-color);
  cursor: pointer;
  path {
    fill: var(--welcome-screen-aws-back-icon-color);
  }
  &:hover,
  &:active,
  &:focus {
    color: var(--welcome-screen-aws-back-icon-color-active);
    path {
      fill: var(--welcome-screen-aws-back-icon-color-active);
    }
  }
  &__icon {
    margin-right: 15px;
  }
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .title {
    font-size: 24px;
    font-family: $base-font-family;
    font-weight: $font-weight-600;
    color: var(--welcome-screen-aws-title-color);
    line-height: 36px;
  }

  .description {
    margin-top: 10px;
    font-family: $secondary-font-family;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
    color: var(--welcome-screen-aws-description-color);
    max-width: 400px;
    text-align: center;
  }

  .welcomeWrapper {
    margin: auto;
    padding: 40px;

    text-align: center;
    .description {
      text-align: left;
    }

    .imageContainer {
      margin-top: 50px;
      margin-bottom: 25px;

      // Tied with icon (changing icon - change this style)
      svg {
        path,
        ellipse {
          &:first-child {
            fill: var(--welcome-screen-aws-image-fill);
          }

          &:not(:first-child) {
            stroke: var(--welcome-screen-aws-image-stroke);
          }
        }
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
    }
  }

  .integrateWrapper {
    margin: auto;
    padding: 40px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .description {
      max-width: 593px;
      margin-bottom: 32px;
      margin-top: 20px;
    }

    .formContainer {
      border-top: 1px solid var(--welcome-screen-aws-form-border-color);
      width: 756px;
      padding-top: 50px;
      margin-top: 75px;

      .formTitle {
        font-family: $base-font-family;
        font-weight: $font-weight-600;
        color: var(--welcome-screen-aws-form-title-color);
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 18px;
        text-align: center;
      }

      .form {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .inputContainer {
          width: 395px;
          margin-right: 30px;
        }

        .buttonContainer {
          margin-top: 12px;
        }
      }
    }
  }
}
