@import "styles/texts.module";

.root {
  line-height: 20px;
  border-radius: 2px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  margin-left: 16px;
  color: var(--label-text-color);
  display: inline-block;
  background-color: var(--label-background-color);
}
.bordered {
  background-color: transparent;
  border: 1.4px solid var(--label-border-color-bordered);
  box-sizing: border-box;
  border-radius: 40px;
  color: var(--label-text-color-bordered);
}
