@import "texts.module";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border-style: unset;
}

.fullWidth {
  width: 100%;
}

.btn_default {
  @extend .btn;
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  min-width: 93px;
  background: var(--primary-main);
  .leftIconContainer,
  .rightIconContainer {
    svg {
      path {
        fill: white;
      }
    }
  }
  &:hover,
  &:active {
    background: var(--primary-main-light);
    .leftIconContainer,
    .rightIconContainer {
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  &:disabled {
    background: var(--black-level-7-color);
    box-shadow: none;
    color: var(--black-level-5-color);
    cursor: default;
    .leftIconContainer,
    .rightIconContainer {
      svg {
        path {
          fill: var(--black-level-5-color);
        }
      }
    }
  }
}

.btn_large_default {
  @extend .btn_default;
  height: 42px;
  font-size: 18px;
  line-height: 42px;
  padding-left: 32px;
  padding-right: 32px;

  .leftIconContainer {
    padding-right: 4px;
  }
  .rightIconContainer {
    padding-left: 4px;
  }
}

.btn_medium_default {
  @extend .btn_default;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  padding-left: 24px;
  padding-right: 24px;

  .leftIconContainer {
    padding-right: 4px;
  }
  .rightIconContainer {
    padding-left: 4px;
  }
}
.btn_small_default {
  @extend .btn_default;
  height: 26px;
  width: auto;
  min-width: 66px;

  font-size: 13px;
  line-height: 26px;
  padding-left: 16px;
  padding-right: 16px;

  .leftIconContainer {
    padding-right: 4px;
  }
  .rightIconContainer {
    padding-left: 4px;
  }
}
.btn_loading_default {
  @extend .btn_default;
  &:hover {
    background: var(--primary-main-light);
  }
  &:disabled {
    background: var(--primary-main);
    box-shadow: none;
    color: white;
    cursor: default;
  }
}
.btn_large_default_loading {
  @extend .btn_loading_default;
  height: 42px;
  font-size: 18px;
  line-height: 140%;
}
.btn_medium_default-loading {
  @extend .btn_loading_default;
  height: 32px;
  font-size: 14px;
  line-height: 140%;
}
.btn_small_default_loading {
  @extend .btn_loading_default;
  height: 26px;
  width: auto;
  min-width: 66px;

  font-size: 13px;
  line-height: 140%;
}
.btn_secondary {
  @extend .btn;

  box-shadow: none;

  min-width: 93px;
  background: $white;
  border: 1px solid var(--black-level-7-color);
  box-sizing: border-box;
  border-radius: 2px;
  color: var(--black-level-2-color);
  font-weight: 600;
  svg {
    path {
      fill: var(--black-level-5-color);
    }
  }
  &:hover {
    border: 1px solid var(--primary-main);
    color: var(--primary-main);
    svg {
      path {
        fill: var(--primary-main);
      }
    }
  }

  &:active {
    border-color: var(--black-level-9-color);
    background: var(--black-level-9-color);
    color: var(--primary-main);
  }

  &:disabled {
    border: 1px solid var(--black-level-8-color);
    color: var(--black-level-5-color);
    cursor: default;
    background: var(--black-level-8-color);

    &:hover {
      background: var(--black-level-8-color);
    }
  }
}

.btn_large_secondary {
  @extend .btn_secondary;
  height: 42px;
  font-size: 18px;
  line-height: 140%;
}

.btn_medium_secondary {
  @extend .btn_secondary;
  height: 32px;
  font-size: 14px;
  line-height: 140%;
}

.btn_small_secondary {
  @extend .btn_secondary;
  height: 26px;
  width: auto;
  min-width: 66px;
  font-size: 13px;
  line-height: 140%;
}
.btn_secondary_loading {
  @extend .btn;
  font-weight: normal;

  background: $white;
  border: 1px solid var(--black-level-7-color);
  box-sizing: border-box;
  border-radius: 2px;
  color: var(--black-level-1-color);

  &:disabled {
    cursor: default;
  }
}
.btn_large_secondary_loading {
  @extend .btn_secondary_loading;
  height: 42px;
  font-size: 18px;
  line-height: 140%;
}
.btn_medium_secondary_loading {
  @extend .btn_secondary_loading;
  height: 32px;
  font-size: 14px;
  line-height: 140%;
}
.btn_small_secondary_loading {
  @extend .btn_secondary_loading;
  height: 26px;
  width: auto;
  min-width: 66px;

  font-size: 13px;
  line-height: 140%;
}
.btn_link {
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: normal;
  text-decoration: unset;
  cursor: pointer;
  color: var(--btn_link-color);

  &:hover,
  &:active {
    color: var(--btn_link-color);
    text-decoration-line: underline;
  }
}

.btn_link_medium {
  @extend .btn_link;
  font-size: 13px;
  line-height: 140%;
}

.btn_link_large {
  @extend .btn_link;
  font-size: 15px;
  line-height: 140%;
}
