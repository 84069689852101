@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";

.root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .wrapper {
    margin: auto;

    .image {
      display: flex;
      justify-content: center;
    }

    .title {
      font-size: 24px;
      font-family: $base-font-family;
      font-weight: $font-weight-600;
      color: var(--welcome-screen-pending-approval-title-color);
      line-height: 36px;
      text-align: center;
    }

    .description {
      width: 560px;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      line-height: 140%;
      color: var(--welcome-screen-pending-approval-description-color);
    }
  }

  .footer {
    color: var(--welcome-screen-pending-approval-footer-color);
    padding-bottom: 64px;
  }
}
