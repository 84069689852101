@import "styles/variables.colors.module";
@import "constants.module";

.root {
  background-color: var(--black-level-0-color);
  border-right: 1px solid var(--black-level-1-color);
  width: $sidebarWidth;
  height: 100vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    margin: 0 12px;
  }

  .mt12 {
    margin-top: 12px;
  }

  .separator {
    margin-top: 10px;
    border-bottom: 1px solid var(--black-level-1-color);
    margin-bottom: 10px;
  }
  .sidebarHead {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-top: 23px;
  }
  .sidebarContent {
    height: 100%;
    overflow: hidden;
    padding: 0 0 6px;
  }
}
.checkbox {
  &.forced,
  &.forced2 {
    padding: 2px;
    border-radius: 2px;
  }
  &.forced.forced2 {
    color: var(--black-level-5-color);
  }
}

.searchInputMargins {
  margin-left: 24px;
  margin-right: 24px;
}

.menuItemTooltip.forced {
  z-index: 1502;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.bottom {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
