@import "styles/common.module.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  font-family: $secondary-font-family;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 65px;
}

.title {
  margin-top: 32px;
  margin-bottom: 16px;
  color: var(--error-screen-title-text-color);
}

.message {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 32px;
  color: var(--error-screen-description-text-color);
  text-align: center;
  max-width: 700px;
  width: 50vw;
  &::first-letter {
    text-transform: capitalize;
  }
}

.footer {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 65px;
  color: var(--error-screen-description-text-color);
}
