@import "styles/common.module.scss";

.tooltip {
  background-color: var(--black-level-1-color) !important;
  padding: 6px 10px;
}
.arrow {
  color: var(--black-level-1-color) !important;
}

.tooltipPlacementLeft,
.tooltipPlacementRight {
  margin: 0 8px;
}
.tooltipPlacementTop,
.tooltipPlacementBottom {
  margin: 8px 0;
}
.tooltipTitle {
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
}
.list {
  .divider {
    margin: 8px 0px;
    background-color: var(--black-level-2-color);
  }
}

@media screen and (max-width: 1024px) {
  [class*="copyButtonTooltip"] {
    max-width: 150px !important;
  }
  [class*="editEngineButtonTooltip"] {
    text-align: center;
    max-width: 65px !important;
  }
}
