@import "styles/variables.colors.module";
@import "styles/variables.fonts.module";

.container {
  .selectRoot {
    border: {
      bottom: {
        width: 1px;
        style: solid;
        color: var(--select-root-bottom-border-color);
      }
    }
    margin-top: floor($labelDefaultFontSize * 1.42);
    max-width: none;
    color: var(--select-root-text-color);

    &.open {
      border-bottom-color: var(--select-root-bottom-border-color-opened);
    }

    &.disabled {
      color: var(--select-root-text-color-disabled);
      border-bottom-color: var(--select-root-bottom-border-color-disabled);
      .icon {
        path,
        rect {
          fill: var(--select-icon-color-disabled);
        }
      }
    }

    .select {
      margin-top: 2px;

      &:focus {
        background-color: rgba(var(--black-level-8-color), 0.3);
      }
    }

    .icon {
      bottom: 3px;
      top: auto;
      path,
      rect {
        fill: var(--select-icon-color);
      }
    }
  }
  .selectedValueWithIcon {
    display: flex;
    align-items: center;
    height: 17px;

    .placeholderIcon {
      margin-right: 4px;
      width: 20px;
      height: 20px;
    }
  }
}

.paper {
  margin-top: 5px;
  max-height: 320px !important;
  // both this important override theme overrides object
  border: 1px solid var(--select-paper-border-color) !important;
  background-color: var(--select-paper-background-color) !important;
  // same for light and dark themes
  box-shadow: 0px 2px 4px 0px rgba($black, 0.2);

  &.paperWidthMD {
    min-width: 240px !important;
  }

  .listWithSearchBox {
    padding-top: 0;
  }

  .noResults {
    text-align: center;
    height: 100%;
    color: var(--select-paper-no-results-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
}

.list {
  &.listWidthMD {
    min-width: 240px !important;
  }
}

.searchMenu {
  overflow: hidden;
}

.childrenItems {
  overflow: auto;
  height: 210px;
}

.searchBoxContainer {
  padding: 8px;
  // fixture for firefox scrollbar
  @-moz-document url-prefix('') {
    padding-right: 12px;
  }

  background-color: var(--select-search-box-background-color);
  position: sticky;
  border-bottom: 1px solid var(--select-search-box-border-color);
  top: 0;
  z-index: 1;

  .selectAllItem {
    padding-left: 6px;
    padding-right: 6px;
    margin-top: 10px;

    &:hover {
      background: none;
    }
  }
}
