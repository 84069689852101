@import "styles/variables.colors.module";

.listItem {
  height: 32px;
  line-height: 140%;
  font-size: 13px;
  font-weight: bold;
  color: var(--black-level-5-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    path,
    rect {
      fill: var(--black-level-5-color);
    }
    margin: 0 auto;
  }

  &:hover {
    color: var(--black-level-7-color);
    cursor: pointer;

    svg {
      path,
      rect {
        fill: var(--black-level-7-color);
      }
    }
  }

  &.selected {
    background-color: var(--black-level-2-color);
    color: var(--white);

    svg {
      path,
      rect {
        fill: var(--white);
      }
    }

    &.disabled {
      background: none;
      cursor: default;
      color: var(--black-level-2-color);
      svg {
        path,
        rect {
          fill: var(--black-level-2-color);
        }
      }
    }
  }

  &.disabled {
    color: var(--black-level-2-color);
    svg {
      path,
      rect {
        fill: var(--black-level-2-color);
      }
    }

    &:hover {
      cursor: default;
      color: var(--black-level-2-color);

      svg {
        path,
        rect {
          fill: var(--black-level-2-color);
        }
      }
    }
  }

  .selectIconContainer {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .text {
    font-size: 13px;
    flex-grow: 1;
  }
}
