@import "styles/variables.colors.module";

@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.root {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;

  .loading {
    position: absolute;
    left: 0px;
    top: 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    bottom: 0px;
    right: 0px;
  }

  .rotation {
    animation-name: ckw;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1s;
  }
}
