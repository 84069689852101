@import "styles/variables.fonts.module";

.root {
  .inputRoot {
    border-bottom: 1px solid var(--input-field-border-bottom-color);
    max-width: none;
    margin-top: floor($labelDefaultFontSize * 1.42);
    color: var(--input-field-input-text-color);

    // connecting to MUI additional classes instead of state selector
    &[class*="Mui-focused"] {
      border-bottom-color: var(--input-field-border-bottom-color-focused);
    }

    &[class*="Mui-disabled"] {
      color: var(--input-field-input-text-color-disabled);
      border-bottom-color: var(--input-field-border-bottom-color-disabled);
    }
  }

  .input {
    margin-top: 0px;
    padding-bottom: 5px;

    &:-webkit-autofill {
      animation-name: onAutoFillStart;
    }

    &:not(:-webkit-autofill) {
      animation-name: onAutoFillCancel;
    }
  }

  .inputError {
    border-bottom: 1px solid var(--input-field-border-bottom-color) !important;
  }

  .asterisk {
    color: var(--input-field-asterisk-color);
  }
}

.errorHelperContainer {
  margin-top: 3px;
  .errorTextContainer {
    visibility: hidden;
    height: 23px;
    padding-top: 1px;
    display: flex;
    align-items: center;
    opacity: 0;
    transform: translateY(-20px);
    transition: transform 0.08s ease-out, opacity 0.3s linear;

    &.active {
      visibility: visible;
      transform: translateY(0px);
      opacity: 1;
    }

    .errorIcon {
      fill: var(--input-field-error-icon-color);
      flex-shrink: 0;
    }

    .errorText {
      font-size: 11px;
      font-weight: 600;
      color: var(--input-field-error-text-color);
      margin-left: 5px;
      letter-spacing: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.overflowVisible {
        overflow: visible;
      }
    }
  }
}

//void animations to catch webkit autofill
@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}

@keyframes onAutoFillCancel {
  from {
    /**/
  }
  to {
    /**/
  }
}
