@import "styles/common.module.scss";

.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  user-select: none;
  font-size: 13px;

  * {
    box-sizing: border-box;
  }
}

.header {
  display: flex;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1;

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.4;
    height: 40px;
    padding: 10px 0 10px 14px;
    border: {
      right: 1px solid var(--table-header-border-color);
      bottom: 1px solid var(--table-header-border-color);
      top: 1px solid var(--table-header-border-color);
    }
    background: var(--table-header-background);
    &:last-child {
      border-right: none;
    }
  }
}

.cell {
  display: flex;
  align-items: flex-start;
  height: auto;
  position: relative;
  border-bottom: 1px solid var(--table-cell-border-color);
}

.table {
  display: flex;
  flex-direction: column;
}

.draggable {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 6px;
  height: 100%;
  z-index: 2;
  transform: translateX(50%);
  cursor: col-resize;
  &:hover {
    background-color: var(--table-draggable-color);
  }
  &__active {
    background-color: var(--table-draggable-color);
    cursor: default;
  }
}

.line {
  position: absolute;
  width: 1px;
  right: 0px;
  top: 0px;
  &__active {
    background-color: var(--table-line-color);
  }
}
.noBorder {
  border-bottom: none;
}
