@import "styles/variables.colors.module";

.root {
  width: 16px;
  height: 16px;
  border: {
    radius: 2px;
    width: 2px;
    style: solid;
    color: var(--checkbox-border-color);
  }
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &:hover {
    border-color: var(--checkbox-border-color-hover);
  }

  &.disabled {
    border-color: var(--checkbox-border-color-disabled);
  }

  .icon {
    display: none;
  }

  &.checked {
    border-color: var(--checkbox-border-color-checked);
    background-color: var(--checkbox-background-color-checked);

    &:hover {
      border-color: var(--checkbox-border-color-checked-hover);
      background-color: var(--checkbox-background-color-checked-hover);
    }

    .icon {
      display: block;
      * {
        fill: var(--checkbox-icon-color-checked);
      }
    }

    &.disabled {
      border-color: var(--checkbox-border-color-checked-disabled);
      background-color: var(--checkbox-background-color-checked-disabled);

      .icon {
        * {
          fill: var(--checkbox-icon-color-checked-disabled);
        }
      }
    }
  }
}

.input {
  // for firefox border bug
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
  border: 0;
}
