@import "styles/variables.colors.module";

.root {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 12px;

  border-bottom: 1px solid var(--black-level-1-color);
  border-top: 1px solid var(--black-level-1-color);
  &:focus {
    outline: none;
  }

  &--noBorderBottom {
    border-bottom: 0;
  }

  .container {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    border-radius: 100px;
    background: var(--black-level-1-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    font-size: 11px;
    font-weight: 600;
    color: var(--black-level-6-color);
  }
}

.header {
  padding: 4px 17px;
  .fullName {
    font-weight: 600;
    font-size: 11px;
    line-height: 140%;
    color: var(--black-level-3-color);
  }

  .accountName {
    font-size: 11px;
    margin-top: 5px;
    color: var(--black-level-5-color);
  }
}

// those icons dont have selected and disabled status so are easily overridable
.forced {
  &.releaseNotes {
    svg {
      path {
        fill: var(--action-menu-item-icon-color);

        &[data-role="body"] {
          fill: none;
          stroke: var(--action-menu-item-icon-color);
        }
      }
    }

    &:hover,
    &:focus {
      svg {
        path {
          fill: var(--action-menu-item-icon-color-hover);

          &[data-role="body"] {
            fill: none;
            stroke: var(--action-menu-item-icon-color-hover);
          }
        }
      }
    }
  }

  &.statusPage {
    svg {
      path {
        stroke: var(--action-menu-item-icon-color);
      }

      circle {
        stroke: var(--action-menu-item-icon-color);
      }
    }

    &:hover,
    &:focus {
      svg {
        path {
          stroke: var(--action-menu-item-icon-color-hover);
        }

        circle {
          stroke: var(--action-menu-item-icon-color-hover);
        }
      }
    }
  }
}
