@import "styles/variables.fonts.module";

.root {
  border: {
    width: 1px;
    style: solid;
    color: var(--textarea-border-color);
    radius: 4px;
  }
  color: var(--textarea-text-color);
  background-color: transparent;
  outline: 0;

  &::placeholder {
    color: var(--textarea-placeholder-color);
  }

  &:hover {
    border-color: var(--textarea-border-color-hover);
  }

  &:focus {
    border-color: var(--textarea-border-color-focus);
  }

  &.disabled {
    color: var(--textarea-text-color-disabled);
    border-color: var(--textarea-border-color-disabled);
  }
}
