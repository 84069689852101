@import "texts.module";

.tabItem {
  display: flex;
  flex-flow: row;
}

.menuItem {
  display: flex;
  flex-flow: row;
  .icon {
    margin-right: 4px;
    flex-shrink: 0;
  }
}
