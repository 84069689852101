@import "styles/common.module.scss";

.snackbar {
  width: auto !important;
}

.paper {
  background-color: var(--black-level-9-color);
  border: 1px solid var(--black-level-8-color);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  max-width: 360px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  white-space: break-spaces;
}

.content {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 1.4;
  overflow: auto;
  max-height: 50vh;
  white-space: break-spaces;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}

.closeIcon {
  cursor: pointer;
  path {
    fill: var(--black-level-5-color);
  }
  &:hover {
    path {
      fill: var(--black-level-2-color);
    }
  }
}
