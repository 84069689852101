.root {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1301;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.transparentClick {
    pointer-events: none;
  }

  .snackbarContainer {
    margin-bottom: 7px;
  }
}
